$( document ).on('turbolinks:load', events)

function events() {
  $('body').on('click', '#export-operations', function() {
    const $filterForm = $('#operations-filter')
    const $formatInput = $filterForm.find('#format')

    $filterForm.attr('data-remote', 'false')
    $formatInput.val('xlsx').trigger('change')
    $filterForm.attr('data-remote', 'true')
    $formatInput.val('js')
  })
}