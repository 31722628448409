$( document ).on('turbolinks:load', common_events)

function common_events(){

    $('.close-modal').on('click', function(){
        $('.form-control-partners-modal').val('');
        $('.form-control-archives-modal').val('');
        $('.input-archives').val('');
        $('.container-files').html('');
        $('.submit-archive').addClass("disabled");
        $('.button-close').click();
        removeArea();
        $('#select-archive-type').val('');
    });

    $('#modalarchive').on('hidden.bs.modal', function(){
        // $('.loader-archive').addClass("hide-loader");
        removeArea();
    });

    $('body').on('click', ".button-close", function(e){
        $(this).parent().remove();
    });

    // add value to hidden_files
    var archiveTypeButton;
    $('body').on('click','.button-archives, .button-archives-operations', function () {
        archiveTypeButton = $(this).data('archive');
    });

    var count = 0;
    var attribute = "archives[archives_attributes]";
    $('body').on('click', '.button-archives', function(){
        removeArea();
        addDrag(count, attribute);
    });

    $('body').on('click', '.button-archives-operations', function(){
        attribute = $(this).data('attributes');
    })

    $("body").on('change', ".input-archives", function(){
        let files = this.files;
        let dataId = $(this).data('id');
        let label = $(this).data('label');
        let dataAttachmentableId = $('#submit-archives').data('controller');
        let dataAttachmentableType = $('#submit-archives').data('type');
        let dataUserId = $('#submit-archives').data('user');

        for (var i = 0; i < files.length; i++) {
            let element = files[i];
            addForm(element, count, dataAttachmentableId, dataAttachmentableType, dataUserId, attribute);
            $('#'+dataId).appendTo('#input-file-archive-'+ count);
            count++;
            $('.label-archive-format').fadeOut(100);
            addDrag(count, attribute);
        }
        addHiddenFieldsArchive();
        $('#'+ label).fadeOut(100);
        $('.submit-archive').removeClass("disabled");
    });

    $('body').on('change', '#select-archive-type', function(){
        archiveTypeButton = $(this).val();
        $('.archive-type').val(archiveTypeButton);
    })

    $('body').on('click', '.submit-form-archive', function(){
        $('.container-archives').find('.input-archives').attr('disabled', true)
    })

    function addForm(file, count, dataAttachmentableId, dataAttachmentableType, dataUserId, attribute){
        var name = file.name;
        var size = (file.size/1024).toFixed(2);
        var parametersArchive = `
        <div class="container-uploading-archives">
            <div class="" id="input-file-archive-${count}"></div>
            <button type="button" class="close button-close ml-auto">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="d-flex flex-row row-archive w-80">
                <div class=""><svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.2302 4.59281L13.323 0.660937C12.9038 0.239063 12.3355 0 11.7444 0H2.23541C1.00149 0.0046875 0 1.0125 0 2.25422V21.7505C0 22.9922 1.00149 24 2.23541 24H15.6516C16.8855 24 17.887 22.9922 17.887 21.7505V6.18609C17.887 5.59125 17.6494 5.01469 17.2302 4.59281ZM11.9261 1.52766C12.0565 1.56047 12.1729 1.62609 12.2708 1.72453L16.178 5.65641C16.2758 5.75484 16.341 5.87203 16.3736 6.00328H11.9261V1.52766ZM16.3969 21.7505C16.3969 22.163 16.0615 22.5005 15.6516 22.5005H2.23541C1.8255 22.5005 1.49012 22.163 1.49012 21.7505V2.25422C1.49012 1.84172 1.8255 1.5 2.23541 1.5H10.4355V6.37828C10.4355 7.00172 10.9339 7.5 11.5534 7.5H16.3969V21.7505Z" fill="#0055B8"/>
                </svg>
                </div>
                <div class="ml-3 name-file">${name}</div>
                <div class="ml-4 name-size name-file">${size} KB</div>
            </div>
            <div class="row mt-40 mb-3">
                <div class="col-sm-12 col-md-4">
                    <div class="form-group">
                        <label for="company_archives_attributes_name_${count}">Nombre</label>
                        <input class="form-control form-control-archives-modal" placeholder="Nombre Archivo..." type="text" id="company_archives_attributes_name_${count}" name="${attribute}[][name]">
                        <div class="error ">
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-8">
                    <div class="form-group">
                        <label for="company_archives_attributes_observations_${count}">Observaciones</label>
                        <input class="form-control form-control-archives-modal" placeholder="Observaciones..." type="text" id="company_archives_attributes_observations_${count}" name="${attribute}[][observations]">
                    </div>
                </div>
                <input type="hidden" class="archive-type" name="${attribute}[][archive_type]">
                <input type="hidden" value="${dataUserId}" class="id-of-user" name="${attribute}[][user_id]">
                <input type="hidden" value="${dataAttachmentableId}" class="company-id" name="${attribute}[][attachmentable_id]">
                <input type="hidden" value="${dataAttachmentableType}" class="company-id" name="${attribute}[][attachmentable_type]">
            </div>
        </div>
        </div>
        `;
        $(parametersArchive).hide().prependTo('.container-archives-form').fadeIn(1000);
    }

    function addDrag(count, attribute){
        var dragAndDrop = `
            <div class="container-form" id="input_${count}">
                <div class="form-group file-area input_${count}">
                    <input class="input-archives" data-label="label-file-${count}" data-id="input_${count}" type="file" required="required" name="${attribute}[][url]">
                    <div class="file-dummy">
                        <div class="success"></div>
                        <div class="default">
                        <strong>Arrastra el archivo para cargarlo</strong><br>
                        o examina en tu computador
                        </div>
                    </div>
                </div>
                <div class="label-archive-format" id="label-file-${count}" ></div>
        `;


        $('.container-archives').prepend(dragAndDrop);
    }

    function removeArea(){
        $('.container-archives, .container-archives-form').html('');
    };

    function addHiddenFieldsArchive(){
        $('.archive-type').val(archiveTypeButton);
    };



    $("body").on('change', ".input-sii-signature", function(){
        let file = $(this).prop('files')[0];
        let name = file.name;
        if(getFileExtension3(name) == 'pfx'){
            $('.file-preview .file-name').html(name)
            $('.file-size').html((file.size/1024).toFixed(2) + ' KB');
            $('.file-preview').removeClass("hide");
            $('.file-area').hide()
            $('#sii_credential_electronic_signature').parent().siblings('.error').html('')
        }else{
            $('.file-area').find('.subtitle').hide()
            $('#sii_credential_electronic_signature').parent().siblings('.error').html(`<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 0C3.35835 0 0 3.35835 0 7.5C0 11.6416 3.35835 15 7.5 15C11.6416 15 15 11.6416 15 7.5C15 3.35835 11.6441 0 7.5 0ZM8.33225 11.8277C8.09726 12.0627 7.79863 12.1679 7.48042 12.1679C7.16221 12.1679 6.86358 12.0602 6.62859 11.8277C6.3936 11.5927 6.26632 11.3161 6.26632 10.9954C6.26632 10.6968 6.3936 10.3982 6.62859 10.1632C6.86358 9.9282 7.16221 9.80091 7.48042 9.80091C7.82066 9.80091 8.09726 9.9282 8.33225 10.1632C8.56723 10.3982 8.69452 10.6748 8.69452 10.9954C8.69452 11.3161 8.56723 11.5927 8.33225 11.8277ZM8.63088 4.70953C8.56723 5.03019 8.48156 5.3484 8.41792 5.68864C8.31022 6.05091 8.20496 6.49886 8.09726 7.03003C7.98956 7.56364 7.86227 8.24413 7.73499 9.05434H7.24543C7.11815 8.24413 6.99086 7.58323 6.88316 7.03003C6.77546 6.47683 6.6702 6.02888 6.58453 5.66661C6.49886 5.28231 6.41319 4.94207 6.34954 4.66547C6.2859 4.38887 6.26387 4.15388 6.26387 3.98254C6.24184 3.6423 6.37157 3.3657 6.62614 3.15029C6.88071 2.91531 7.15976 2.81005 7.5 2.81005C7.84024 2.81005 8.13887 2.91775 8.37386 3.15029C8.60885 3.38528 8.73613 3.66188 8.73613 3.98254C8.73613 4.15633 8.69452 4.38887 8.63088 4.70953Z" fill="#FF5C4E"/>
            </svg> Formato de archivo incorrecto. Cargar sólo en PFX.`);
        }

    });

    function getFileExtension3(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    $("body").on('click', ".file-preview .close", function (){
        $('.file-area').show();
        $('.file-area').find('.subtitle').show();
        $('.file-preview').addClass("hide");
        $('.file-preview .file-name').html("");
        $('.input-sii-signature').val("");
        if($('.input-sii-signature').val("").val() == "" || $('#sii_credential_electronic_signature_password').val() == ""){
            $('#buttons-modal-sii').find('input[type="submit"]').addClass('disabled');
        }else{
            $('#buttons-modal-sii').find('input[type="submit"]').removeClass('disabled');
        }
    });

    $('body').on('click', '.link-download-archives', function (e) {
        e.preventDefault();
        let url = $(this).data('url')
        window.open(url)
        // location.href = url, '_blank';
    })

    $('body').on('click', '#edit-archives', function () {
        if($('.td-hide-archive').hasClass("d-none") ){
            $('.td-hide-archive').removeClass("d-none");
            $(this).html("Finalizar");
        }else{
            $('.td-hide-archive').addClass("d-none");
            $(this).html("Editar Documentos");
        }
    })
    $('body').on("click", ".show-document-eye", function(){
        let close_icon = $('#close-document-button > span > svg > path')
        close_icon.attr("fill",'#0055B8')
        let url = $(this).data('url')
        let $modal = $('#show-document-modal')

        $modal.find('#document-display-div').html($('<embed></embed>', {
            width: "100%", height: "50vh",
            src : url,
            id: "document-opener"
        }))
        $('#download-document').data('url', url)
        $('#show-document-modal').modal('toggle')
    })

}