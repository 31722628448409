import customer from './consumer';

function debtorCompanyCreated(data) {
  if (data.user_acceses) {
    data.user_acceses.forEach(userAccess => {
      const $debtorContactContainer = $(`#debotor-company-${userAccess.company_id}-contact`)
      $debtorContactContainer.find('#contact-count').html(Number($debtorContactContainer.find('#contact-count').html()) + 1)
      $debtorContactContainer.find('#contact-div').removeClass('d-none')
      $debtorContactContainer.find('#no-contact-div').addClass('d-none')
    });
  }
}

function updateBusinessReport(data) {
  const {
    message,
    title
  } = data;

  $modals.info({ title, message })
}

function createInstallmentsForLeasing(data) {
  const {
    message,
    title
  } = data;

  $modals.info({ title, message })
}

function finishCreateInstallmentsForLeasing(data) {
  const {
    message,
    title,
    deal_id
  } = data;

  $modals.success({ title, message,
    acceptText: 'Ir al negocio',
    onAccept: () => { location.replace("/negocios/" + deal_id + "/edit")}
  })
}

customer.subscriptions.create({ channel: 'DealsChannel' }, {
  received(data) {
    if (data.action === 'debtor_company_created') debtorCompanyCreated(data)
    if (data.action === 'update_business_report') updateBusinessReport(data)
    if (data.action === 'create_installments_for_leasing') createInstallmentsForLeasing(data)
    if (data.action === 'finish_create_installments_for_leasing') finishCreateInstallmentsForLeasing(data)
  }
})